import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import EffectoIcon from '../assets/icons/EffectoLogo.svg';
import Button from 'pages/start/results/components/StartNowButton';
import { Text } from 'components';
import SocialRow from './SocialRow';
import { useRouter } from 'apis/history';
import { mobile, useQuery } from 'styles/breakpoints';

const Footer = () => {
  const {
    goToPrivacy,
    goToTerms,
    goToLanding,
    goToStory,
    goToReviews,
    goToFAQ,
    goToContact,
    goToStarterGuide,
  } = useRouter();
  const { isMobile } = useQuery();

  return (
    <FooterWrap>
      <FooterContainer>
        <FooterRow>
          <FooterCol>
            <LinksTitle>Company</LinksTitle>
            <Links>
              <LinkWrap onClick={() => goToStory()}>
                <Link>Our Story</Link>
              </LinkWrap>
              <LinkWrap onClick={() => goToReviews()}>
                <Link>Reviews</Link>
              </LinkWrap>
              <LinkWrap onClick={() => goToFAQ()}>
                <Link>FAQ</Link>
              </LinkWrap>
              <LinkWrap onClick={() => goToStarterGuide()}>
                <Link>Started Guide</Link>
              </LinkWrap>
              <LinkWrap onClick={() => goToContact()}>
                <Link>Contact Us</Link>
              </LinkWrap>
            </Links>
          </FooterCol>
          <FooterColRight>
            <ButtonTitle>Take control of your health</ButtonTitle>
            <Button onClick={() => goToLanding()}>START THE QUIZ</Button>
          </FooterColRight>
        </FooterRow>
        <FooterCopy>
          <CopyLeft>
            <CopyLeftItem>
              <CopyItemLink>
                <PaddingWrap>
                  <EffectoLogo />
                  <StyledText>
                    <strong onClick={() => goToLanding()}>Effecto</strong> ©
                    2023. All rights reserved
                  </StyledText>
                </PaddingWrap>
              </CopyItemLink>
            </CopyLeftItem>
            {isMobile ? null : <Dot />}
            <CopyLeftItem>
              <CopyItemLink onClick={() => goToTerms()}>
                <StyledText>Terms & Conditions</StyledText>
              </CopyItemLink>
            </CopyLeftItem>
            <Dot />
            <CopyLeftItem>
              <CopyItemLink onClick={() => goToPrivacy()}>
                <StyledText>Privacy Policy</StyledText>
              </CopyItemLink>
            </CopyLeftItem>
          </CopyLeft>
          <SocialRow />
        </FooterCopy>
      </FooterContainer>
    </FooterWrap>
  );
};

export default Footer;

const PaddingWrap = styled.div`
  display: flex;
  align-items: center;
  @media ${mobile} {
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-right: 10px;
  }
`;

const StyledText = styled(Text)`
  font-size: 0.875rem;
`;

const Dot = styled.div`
  background: #555770;
  border-radius: 50%;
  content: '';
  display: block;
  height: 2px;
  margin: 0 17px;
  width: 2px;
`;

const EffectoLogo = styled(EffectoIcon)`
  height: 24px;
  margin-right: 12px;
  width: 24px;
`;

const CopyItemLink = styled.div`
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CopyLeftItem = styled.li`
  align-items: center;
  display: flex;
`;

const CopyLeft = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  letter-spacing: -0.024em;
  line-height: 140%;
  list-style: none;
}
`;

const ButtonTitle = styled(Text)`
  margin-bottom: 1.5rem;
  color: #1c1c28;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.024em;
  line-height: 140%;
  font-family: Inter;
  @media ${mobile} {
    font-weight: 600;
  }
`;

const Link = styled.a`
  font-family: Inter;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
`;

const LinkWrap = styled.li`
  list-style: none;
  margin-right: 32px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.024em;
  line-height: 140%;
  @media ${mobile} {
    margin-bottom: 24px;
  }
`;

const Links = styled.ul`
  margin: 13px 0;
  display: flex;
  flex-wrap: wrap;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const LinksTitle = styled(Text)`
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FooterCopy = styled.div`
  align-items: center;
  border-top: 1px solid #e4e4eb;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  padding-bottom: 48px;
  padding-top: 48px;
  @media ${mobile} {
    flex-direction: column-reverse;
  }
`;

const FooterCol = styled.div`
  width: 50%;
`;

const FooterColRight = styled.div`
  margin-left: auto;
  min-width: 330px;
  width: 29%;
  @media ${mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const FooterWrap = styled.footer`
  border-top: 1px solid #e4e4eb;
  padding-top: 64px;
  display: block;
  @media ${mobile} {
    padding-top: 48px;
  }
`;

const FooterContainer = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
