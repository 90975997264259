import React, { useEffect, useRef, useState } from 'react';

import Telegram from '../assets/icons/social/Telegram.svg';
import FB from '../assets/icons/social/FB.svg';
import IG from '../assets/icons/social/IG.svg';
import Reddit from '../assets/icons/social/Reddit.svg';
import TikTok from '../assets/icons/social/TikTok.svg';
import Twitter from '../assets/icons/social/Twitter.svg';
import styled from 'styled-components';

const SocialRow = () => (
  <SocialLinkContainer>
    <SocialLinkItem>
      <SocialLinkLink
        href="https://www.reddit.com/r/EffectoApp/"
        target="_blank"
      >
        <RedditIcon />
      </SocialLinkLink>
    </SocialLinkItem>

    <SocialLinkItem>
      <SocialLinkLink href="https://twitter.com/EffectoApp" target="_blank">
        <TwitterIcon />
      </SocialLinkLink>
    </SocialLinkItem>

    <SocialLinkItem>
      <SocialLinkLink
        href="https://www.facebook.com/TheEffectoApp"
        target="_blank"
      >
        <FBicon />
      </SocialLinkLink>
    </SocialLinkItem>

    <SocialLinkItem>
      <SocialLinkLink
        href="https://www.instagram.com/effecto.app/"
        target="_blank"
      >
        <IGicon />
      </SocialLinkLink>
    </SocialLinkItem>
  </SocialLinkContainer>
);

export default SocialRow;

const FBicon = styled(FB)`
  margin-left: 22px;
`;

const TwitterIcon = styled(Twitter)`
  margin-left: 22px;
`;

const RedditIcon = styled(Reddit)`
  margin-left: 22px;
`;

const IGicon = styled(IG)`
  margin-left: 22px;
`;

const SocialLinkLink = styled.a`
  cursor: pointer;
`;

const SocialLinkItem = styled.li``;

const SocialLinkContainer = styled.ul`
  list-style: none;
  display: flex;
`;
